<template>
  <div class="container mt-5 px-md-5" style="margin-left:auto; margin-right:auto;">
    <div class=" mb-4">
        <h2 class="text-left">{{title}}</h2>
    </div>
    <div class="container-fluid p-0">
      <button @click="showModal(1)" class="btn-primary"><i class="fa fa-plus mr-2"></i>Tarjeta</button>
    </div>
    <div class="my-4 mb-2">
      <!-- <h2 class="text-left">Mis solicitudes</h2> -->
    </div>


    <!-- tabla test -->
    <div class="bg-gray ">
      <b-table 
          hover
          responsive 
          :items="table_list" 
          :fields="fields" 
          :current-page="currentPage" 
          :per-page="perPage" 
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
      >
      <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
      </template>
              <template #cell(fecha_inicio)="data">
                  <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_inicio).utc().format("DD-MM-YYYY") : '' }}</span>
              </template>
              <template #cell(fecha_fin)="data">
                  <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_fin).utc().format("DD-MM-YYYY") : '' }}</span>
              </template>
              <template #cell(action)="data">
                  <button @click="showModal(2, data.item)" class="btn-white-outline-small mx-1"><i class="fa fa-pen"></i></button>
                  <button @click="deleteItem(data.item)" class="btn-primary-small mx-1"><i class="fa fa-trash"></i></button>
              </template>
      <template #row-details="row">
          <b-card>
          <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
          </b-card>
      </template>
      </b-table>
        <div>
          
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>


    <!-- show details and edit -->    
    
  <b-modal ref="modal-item2" hide-footer >
    <template #modal-title>
      <h3>{{modalText}}</h3>
    </template>

    <template>
      <form @submit.prevent="saveData()" style="padding:10px">
        <div class="row">
          <div class="col-9">
            <label for="">Nombre</label>
            <input required class="form-control" v-model="row.titulo" type="text" name="" id="">
          </div>
          <div class="col-3">
            <b-form-checkbox class="mt-4" size="lg" id="checkbox-2" v-model="row.privado" name="checkbox-2" value="1" unchecked-value="0" > 
                <span for="observaciones" class="h6">Privado</span>
            </b-form-checkbox>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <label for="">Descripción</label>
            <input required class="form-control" v-model="row.descripcion" type="text" name="" id="">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <label for="">Categoría</label>
            <!-- <input class="form-control" v-model="row.urlimagen" type="text" name="" id=""> -->
              <b-form-select @change="getSubcategories()" required v-model="row.categoria_id" class="mb-3 form-control">
                  <b-form-select-option class="text-center" v-for="item of categorias" 
                  :value="item.id" :key="item.id">{{ item.nombre }} </b-form-select-option>
              </b-form-select>            
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <label for="">Subcategoría</label>
            <!-- <input class="form-control" v-model="row.urlimagen" type="text" name="" id=""> -->
              <b-form-select required v-model="row.subcategoria_id" class="mb-3 form-control">
                  <b-form-select-option class="text-center" v-for="item of subcategorias" 
                  :value="item.id" :key="item.id">{{ item.titulo }} </b-form-select-option>
              </b-form-select>            
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <label for="">Imagen</label><br>
            <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('promociones')"/><br>
            <input class="form-control" v-model="row.urlimagen" type="text" name="" id="">
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <b-button type="submit" class="btn-primary-small mx-1 float-right ml-3">Guardar</b-button>
            <b-button @click="$refs['modal-item2'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button>
          </div>
        </div>
      </form>
    </template>
  </b-modal>  
  </div>
</template>

<script>
// import FormVue from "@/components/Form.vue";
export default {
  components: {
    // FormVue,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      selected: null,
        categorias: [
        ],
        subcategorias: [
        ],
        Producto: [
        ],
      perPage: 25,
      currentPage: 1,

      table: "promociones",
      modalText: 'Nueva Ficha',
      title: 'Promociones',
      items: [],

      table_list: [
      ],
      row:{},
      categoria_id: 0,
      fields: [
          { key: 'id', label: 'id', sortable: true, sortDirection: 'desc', class: 'text-center' },
          { key: 'titulo', label: 'Titulo', sortable: true, class: 'text-center' },
          { key: 'categoria', label: 'Categoría', sortable: true, class: 'text-center' },
          { key: 'subcategoria', label: 'Subcategoría', sortable: true, class: 'text-center' },
          // { key: 'fecha_inicio', label: 'fecha inicio', sortable: true, class: 'text-center' },
          // { key: 'fecha_fin', label: 'fecha fin', sortable: true, class: 'text-center' },
          // { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },
          { key: 'action', label: 'Acciones',class: 'text-center'},

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      file1: null,
      filename1: null,
    };
  },
  async mounted() {
    await this.getData();
    await this.getCategories();
  },
  methods: {
    onFileSelected(type, item){
      this.file1 = event.target.files[0];
      this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
      this.filename1 = type + '_' + this.filename1+ '.jpg';
      console.log(type,',',item)
    },
    async showModal(type, item){
      console.log(item)
      if(type == 2){
        this.modalText = 'Editar Ficha'
        this.row = item
        await this.getSubcategories()
      }
      else{
        this.row = {}
        this.modalText = 'Nueva Ficha'
      }
      this.$refs['modal-item2'].show()
    },
    async getCategories() {
      try {
        let res = await this.$store.dispatch("get", { path: 'categorias/getAll'});
        console.log('------------->',res)
        if(res.length>0){
          this.categorias = res
        }
        else{
          this.categorias = []
        }
      } catch (error) {
        this.categorias = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error:" + error,
        });
      }
    },
    async getSubcategories() {
      console.log('sub', this.row.categoria_id)
      try {
        let res = await this.$store.dispatch("get", { path: 'subcategorias/getAllByCategory/' + this.row.categoria_id});
        console.log('------------->',res)
        if(res.length>0){
          this.subcategorias = res
        }
        else{
          this.subcategorias = []
        }
      } catch (error) {
        this.subcategorias = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error:" + error,
        });
      }
    },
    async getData() {
      try {
        this.$isLoading(true)        
        let res = await this.$store.dispatch("get", { path: this.table + '/getAll/'});
        this.$isLoading(false)        
        //console.log(res)
        if(res.length>0){
          this.table_list = res
        }
        else{
          this.table_list = []
        }
      } catch (error) {
        this.$isLoading(false)        
        this.table_list = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async saveData() {
      try {
        let req
        let uploadImg
        this.$isLoading(true)        
        if(this.filename1!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename1 ,file: this.file1})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.urlimagen = '../img/' + this.filename1
          }
        }
        //this.row.categoria_id = this.categoria_id
        //console.log(this.row)
        if(this.row.id){
          let path = this.table + '/modify/'+ this.row.id
          //console.log('path', path)
          delete this.row.categoria
          delete this.row.subcategoria
          req = await this.$store.dispatch("put", {
            path: path,
            data: this.row
          });
        }
        else{
          req = await this.$store.dispatch("post", {
            path: this.table + '/new',
            data: this.row
          });
        }
        this.$isLoading(false)
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });
        this.$refs['modal-item2'].hide()
        this.getData();
      } catch (error) {
        this.$isLoading(false)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar datos.",
        });        
        
      }
    },
    ///////////////
    gotoTanks(){
      console.log('voy a ...')
      this.$router.push('/admin/gracias_solicitud')
    },

    async deleteItem(item) {
      try {
        console.log(item.id)
        const sure = await confirm(
          "¿Está seguro que desea eliminar este elemento?"
        );
        if (!sure) {
          return;
        }

        const req = await this.$store.dispatch("delete", {
          path: this.table +'/' + item.id,
        });
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Registro eliminado correctamente!",
        });        
        this.getData();
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Opps!",
          text: "Error: " + error,
        });        
        console.log(error)
        
      }
    },
  },
};
</script>

<style>

 

</style>